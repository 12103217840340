import { onMounted, reactive, ref } from "vue";
import { useRouter } from 'vue-router';
import { update, account, merchant, storeDealer } from '@/api/index';
import { Notify, Toast } from 'vant';
import cityDatas from '@/assets/js/cityData.js';
import { cutPictureUrl, inputBlur } from '@/assets/js/compontent.js';
export default {
  name: 'Brand',
  components: {
    [Notify.name]: Notify
  },

  setup() {
    const state = reactive({
      store_logo: '',
      store_name: '',
      store_number: '',
      store_cascader: '',
      store_mode: '',
      store_mode_name: '',
      store_type_name: '',
      store_type: 0,
      province: '',
      city: '',
      district: '',
      dealer_id: '',
      store_group_id: '',
      address: '',
      latitude: '',
      longitude: '',
      province_name: '',
      city_name: '',
      district_name: '',
      user_name: '',
      tel: '',
      distance: '',
      store_stime: '',
      store_etime: '',
      pay_type: '',
      pay_type_name: '',
      merchant_id: '',
      merchant_id_name: ''
    });
    const router = useRouter();
    const inputBlurs = inputBlur();
    const showPicker = ref(false);
    const pickerType = ref('');
    const cityData = cityDatas.data;
    const columns = [{
      id: 1,
      name: '网店'
    }, {
      id: 2,
      name: '配送店'
    }, {
      id: 3,
      name: '线下门店'
    }, {
      id: 4,
      name: '商超'
    }, {
      id: 5,
      name: '售货机'
    }];
    const store_types = {
      text: 'name',
      value: 'id'
    };

    const validator = value => value.replace(/[^0-9.]/g, '');

    const storeMode = [{
      id: 1,
      name: '经销门店'
    }, {
      id: 2,
      name: '加盟门店'
    }, {
      id: 3,
      name: '联营门店'
    }, {
      id: 4,
      name: '直营门店'
    }, {
      id: 5,
      name: '其他'
    }];
    const payType = [{
      id: 0,
      name: '当面付'
    }, {
      id: 1,
      name: '直付通'
    }];
    const merchantLists = ref([]);
    const store_logo = ref([]);

    const onClickLeft = () => {
      router.replace({
        path: '/login'
      });
    };

    const onConfirm = value => {
      if (pickerType.value == 'store_stime' || pickerType.value == 'store_etime') {
        state[pickerType.value] = value;
      } else {
        state[pickerType.value + '_name'] = value.name;
        state[pickerType.value] = value.id;
      }

      if (pickerType.value == 'pay_type') {
        merchants();
      }

      showPicker.value = false;
    };

    const showPickers = data => {
      showPicker.value = true;
      pickerType.value = data;
    };

    const fieldNames = {
      text: 'name',
      value: 'id',
      children: 'subList'
    };

    const onFinish = ({
      selectedOptions
    }) => {
      showPicker.value = false;
      state.store_cascader = selectedOptions.map(option => option.name).join(' ');
      state.province = selectedOptions[0].id;
      state.province_name = selectedOptions[0].name;
      state.city = selectedOptions[1].id;
      state.city_name = selectedOptions[1].name;
      state.district = selectedOptions[2].id;
      state.district_name = selectedOptions[2].name;
    };

    const parseStore = () => {
      let geocoder = new AMap.Geocoder();

      if (state.province_name == '' || state.city_name == '' || state.district_name == '') {
        return Toast.fail('请先选择省市区');
      }

      if (state.address == '') {
        return Toast({
          message: '请先填写详细地址',
          position: 'top'
        });
      }

      var keyword = state.province_name + '' + state.city_name + '' + state.district_name + '' + state.address;
      geocoder.getLocation(keyword, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          let lnglat = result.geocodes[0].location.toString();
          lnglat = lnglat.split(',');
          state.latitude = lnglat[1];
          state.longitude = lnglat[0];
        }
      });
    };

    const merchants = async () => {
      let merchantList = await merchant({
        pay_type: state.pay_type
      });
      merchantLists.value = merchantList.data.map(item => {
        item.name = item.alias_name;
        return item;
      });
    }; //logo删除


    const deleteStore = () => {
      state.store_logo = '';
      store_logo.value = [];
    };

    const onSubmit = async () => {
      let {
        store_logo,
        store_name,
        store_number,
        store_mode,
        province,
        store_type,
        city,
        district,
        dealer_id,
        store_group_id,
        address,
        latitude,
        longitude,
        user_name,
        tel,
        distance,
        store_stime,
        store_etime,
        pay_type,
        merchant_id
      } = state;
      store_logo = cutPictureUrl(store_logo);
      let zft_id = '';

      if (pay_type == '1') {
        zft_id = merchant_id;
      }

      let res = await storeDealer({
        store_logo,
        store_name,
        store_number,
        store_mode,
        province,
        store_type,
        city,
        district,
        dealer_id,
        store_group_id,
        address,
        latitude,
        longitude,
        user_name,
        tel,
        distance,
        store_stime,
        store_etime,
        pay_type,
        merchant_id,
        zft_id
      });

      if (res.error == 0) {
        router.push({
          path: '/success'
        });
      }
    };

    const afterRead = async file => {
      let formData = new FormData();
      formData.append('file', file.file);
      formData.append('scene', 'store'); // 此时可以自行将文件上传至服务器

      const res = await update(formData);
      state.store_logo = res.data.url;
      store_logo.value = [{
        url: res.data.url
      }];
    };

    const actAccount = async () => {
      const data = await account();
      state.store_number = data.data;
    };

    onMounted(() => {
      merchants();
    });
    return {
      state,
      onSubmit,
      showPicker,
      afterRead,
      actAccount,
      onConfirm,
      columns,
      showPickers,
      pickerType,
      storeMode,
      fieldNames,
      onFinish,
      cityData,
      payType,
      parseStore,
      merchants,
      merchantLists,
      store_types,
      store_logo,
      deleteStore,
      onClickLeft,
      validator,
      inputBlurs
    };
  }

};